<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        For each of the reactions below, determine the initial and final oxidation states (OS) of
        each of the atoms as well as the oxidizing and reducing agents.
      </p>
      <!---------------------------------------------------------------------------------------------->
      <p class="mb-0 pb-3">
        <chemical-latex content="a) Mg(s) + NiCl2(aq) -> MgCl2(aq) + Ni(s)" />
      </p>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <stemble-latex content="$\textbf{Element}$" />
        </v-col>
        <v-col md="2">
          <stemble-latex content="$\textbf{Initial OS}$" />
        </v-col>
        <v-col md="2">
          <stemble-latex content="$\textbf{Final OS}$" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="i) Mg" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSA1I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSA1F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="ii) Ni" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSA2I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSA2F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-3" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="iii) Cl" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSA3I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSA3F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>

      <stemble-latex class="pl-5 mb-0" content="$\textbf{iv) Oxidizing Agent}$" />

      <v-radio-group
        v-model="inputs.OxAgentA"
        row
        class="pl-10 mb-0"
        :disabled="!allowEditing"
        dense
      >
        <v-radio v-for="option in options1" :key="option.value" class="mb-0" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <stemble-latex class="pl-5 mb-0" content="$\textbf{v) Reducing Agent}$" />

      <v-radio-group
        v-model="inputs.RedAgentA"
        row
        class="pl-10 mb-0"
        :disabled="!allowEditing"
        dense
      >
        <v-radio v-for="option in options1" :key="option.value" class="mb-4" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <!---------------------------------------------------------------------------------------------->
      <p class="mb-0 pb-3">
        <chemical-latex content="b) C2H4(g) + 3O2(g) -> 2CO2(g) + 2H2O(g)" />
      </p>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <stemble-latex content="$\textbf{Element}$" />
        </v-col>
        <v-col md="2">
          <stemble-latex content="$\textbf{Initial OS}$" />
        </v-col>
        <v-col md="2">
          <stemble-latex content="$\textbf{Final OS}$" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="i) C" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSB1I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSB1F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="ii) H" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSB2I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSB2F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="iii) O" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSB3I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSB3F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>

      <stemble-latex class="pl-5 mb-0" content="$\textbf{iv) Oxidizing Agent}$" />

      <v-radio-group
        v-model="inputs.OxAgentB"
        row
        class="pl-10 mb-0"
        :disabled="!allowEditing"
        dense
      >
        <v-radio v-for="option in options2" :key="option.value" class="mb-0" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <stemble-latex class="pl-5 mb-0" content="$\textbf{v) Reducing Agent}$" />

      <v-radio-group
        v-model="inputs.RedAgentB"
        row
        class="pl-10 mb-0"
        :disabled="!allowEditing"
        dense
      >
        <v-radio v-for="option in options2" :key="option.value" class="mb-4" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <!---------------------------------------------------------------------------------------------->
      <p class="mb-0 pb-3">
        <chemical-latex content="c) Zn(s) + H2SO4(aq) -> ZnSO4(aq) + H2(g)" />
      </p>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <stemble-latex content="$\textbf{Element}$" />
        </v-col>
        <v-col md="2">
          <stemble-latex content="$\textbf{Initial OS}$" />
        </v-col>
        <v-col md="2">
          <stemble-latex content="$\textbf{Final OS}$" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="i) Zn" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC1I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC1F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="ii) H" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC2I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC2F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="iii) S" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC3I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC3F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>
      <v-row class="pl-10 mb-0" align="center" fill-height>
        <v-col md="2">
          <chemical-latex content="iv) O" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC4I" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
        <v-col md="2">
          <calculation-input v-model="inputs.OSC4F" dense class="pb-0" :disabled="!allowEditing" />
        </v-col>
      </v-row>

      <stemble-latex class="pl-5 mb-0" content="$\textbf{v) Oxidizing Agent}$" />

      <v-radio-group
        v-model="inputs.OxAgentC"
        row
        class="pl-10 mb-0"
        :disabled="!allowEditing"
        dense
      >
        <v-radio v-for="option in options3" :key="option.value" class="mb-0" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <stemble-latex class="pl-5 mb-0" content="$\textbf{vi) Reducing Agent}$" />

      <v-radio-group
        v-model="inputs.RedAgentC"
        row
        class="pl-10 mb-0"
        :disabled="!allowEditing"
        dense
      >
        <v-radio v-for="option in options3" :key="option.value" class="mb-4" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <!---------------------------------------------------------------------------------------------->
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question228',
  components: {
    StembleLatex,
    CalculationInput,
    ChemicalLatex,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        OSA1I: null,
        OSA1F: null,
        OSA2I: null,
        OSA2F: null,
        OSA3I: null,
        OSA3F: null,
        OxAgentA: null,
        RedAgentA: null,
        OSB1I: null,
        OSB1F: null,
        OSB2I: null,
        OSB2F: null,
        OSB3I: null,
        OSB3F: null,
        OxAgentB: null,
        RedAgentB: null,
        OSC1I: null,
        OSC1F: null,
        OSC2I: null,
        OSC2F: null,
        OSC3I: null,
        OSC3F: null,
        OSC4I: null,
        OSC4F: null,
        OxAgentC: null,
        RedAgentC: null,
      },
      options1: [
        {text: '$\\ce{Mg}$', value: 'mg'},
        {text: '$\\ce{NiCl2}$', value: 'nicl2'},
      ],
      options2: [
        {text: '$\\ce{C2H4}$', value: 'c2h4'},
        {text: '$\\ce{O2}$', value: 'o2'},
      ],
      options3: [
        {text: '$\\ce{Zn}$', value: 'zn'},
        {text: '$\\ce{H2SO4}$', value: 'h2so4'},
      ],
    };
  },
};
</script>
